.hero-spacer {
  padding-top: 120px;
}

.native-to-search .hero-section + .hero-spacer {
  padding-top: 80px;
}

.section-spacer {
  padding-top: 130px;
}

@media (max-width: 1439px) {
  .section-spacer {
    padding-top: max(100px, 9.02vw);
  }
}

.native-to-search .monetize-section + .section-spacer {
  padding-top: 30px;
}

@media (max-width: 959px) {
  .section-spacer {
    padding-top: 100px;
  }

  .native-to-search .monetize-section + .section-spacer {
    padding-top: 100px;
  }
}

@media (max-width: 767px) {
  .native-to-search .monetize-section + .section-spacer {
    padding-top: 70px;
  }

  .hero-spacer {
    padding-top: 40px;
  }

  .native-to-search .hero-section + .hero-spacer {
    padding-top: 0;
  }

  .section-spacer {
    padding-top: 70px;
  }
}

.publisher-app-section__spacer {
  padding-top: 270px;
}

@media (max-width: 1439px) {
  .publisher-app-section__spacer {
    padding-top: calc(110px + max(130px, 11.11vw));
  }
}

@media (max-width: 959px) {
  .publisher-app-section__spacer {
    padding-top: 100px;
  }
}

@media (max-width: 767px) {
  .publisher-app-section__spacer {
    padding-top: 70px;
  }
}

.section-spacer--bottom-blog {
  background-color: #FFFFFF;
}

@media (max-width: 767px) {
  .fast-payouts +  .section-spacer {
    padding-top: 0;
  }

  .faqs-page-section + .section-spacer {
    padding-top: 0;
  }

  .section-spacer--bottom-blog {
    padding-top: 0;
  }

  .blog-part-section + .section-spacer {
    padding-top: 0;
  }

  .payout-models + .section-spacer {
    padding-top: 0;
  }

  .extensions .monetize-section + .section-spacer {
    padding-top: 0;
  }

  .native-to-search .monetize-section + .section-spacer {
    padding-top: 100px;
  }
}

.photobomb-us-section + .section-spacer {
  padding-top: 30px;
}

@media (max-width: 767px) {
  .blog-part-section + .section-spacer {
    padding-top: 0;
  }
}

/* combine-search-section */

.native-to-search .advantages + .section-spacer {
  padding-top: 70px;
}

@media (max-width: 959px) {
  .native-to-search .advantages + .section-spacer {
    padding-top: 100px;
  }
}

@media (max-width: 767px) {
  .native-to-search .advantages + .section-spacer {
    padding-top: 70px;
  }
}
